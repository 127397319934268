import './App.css'
import { Route, Switch } from 'react-router-dom'
import Pdf from './Pdf'
import HomeScreen from './HomeScreen'

function App() {
  return (
    <div className="App">
        <Switch>
            <Route exact path="/">
                <HomeScreen />
            </Route>
            <Route path="/superyacht-fouling-control">
                <Pdf />
            </Route>
        </Switch>
    </div>
  );
}

export default App;
