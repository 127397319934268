import React, { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
}

export default function Pdf() {
  const file = '/82672_H_SY_Brochure_Fouling_Control_UK_2024_web.pdf'
  const [numPages, setNumPages] = useState(null)

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages)
  }

  return (
    <div className="Pdf">
      <div>
      <a href={file} download="82672_H_SY_Brochure_Fouling_Control_UK_2024_web" className="downloadButton">Download the brochure</a>
        <div className="">
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {
              Array.from(
                new Array(numPages),
                (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ),
              )
            }
          </Document>
        </div>
      </div>
    </div>
  )
}