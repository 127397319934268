import { Link } from 'react-router-dom'

function HomeScreen() {
    return (
      <div className="App">
        <img className="Logo" alt="Hempel logo" src="logo.png"></img>
        <h2>HEMPEL SUPERYACHT MATERIALS</h2>
        <Link to="/superyacht-fouling-control">PDF: SuperYacht Fouling Control</Link>
      </div>
    )
  }
  
  export default HomeScreen